import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa, ViewType } from '@supabase/auth-ui-shared';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import {
  FORGOT_PASSWORD_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
  MAGIC_PAGE_ROUTE,
  PRIVACY_PAGE_ROUTE,
  QUERY_PARAM_REDIRECTED_FROM,
  SIGNUP_PAGE_ROUTE,
  TOS_PAGE_ROUTE,
  WORKSPACE_PAGE_ROUTE,
} from '@routes';
import flux from '@images/flux.svg';
import { Database } from '../supabase/types.gen';
import { useTranslations } from 'next-intl';

type Props = {
  title: string;
  view: ViewType;
};

const LoginComponent = ({ title, view }: Props) => {
  const supabaseClient = useSupabaseClient<Database>();
  const user = useUser();
  const router = useRouter();
  const redirectTo = (router.query[QUERY_PARAM_REDIRECTED_FROM] as string) ?? WORKSPACE_PAGE_ROUTE;
  const t = useTranslations('Components.LoginComponent');

  useEffect(() => {
    if (user) {
      router.push(redirectTo);
    }
  });

  if (!user)
    return (
      <>
        <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Image
              className="mx-auto h-12 w-auto"
              src={flux}
              height={144}
              width={144}
              alt="flux.chat"
            />
            <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
              {title}
            </h2>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Auth
              appearance={{ theme: ThemeSupa }}
              supabaseClient={supabaseClient}
              providers={[]}
              magicLink={true}
              showLinks={false}
              view={view}
            />

            <div className="mt-6 flex flex-col items-center text-xs text-gray-600 hover:text-gray-500 underline">
              {(() => {
                switch (view) {
                  case 'magic_link':
                    return <Link href={LOGIN_PAGE_ROUTE}>{t('already_have_account')}</Link>;
                  case 'sign_in':
                    return (
                      <>
                        <Link href={MAGIC_PAGE_ROUTE}>{t('send_magic_link')}</Link>
                        <Link className="mt-2" href={FORGOT_PASSWORD_PAGE_ROUTE}>
                          {t('forgot_password')}
                        </Link>
                        <Link className="mt-2" href={SIGNUP_PAGE_ROUTE}>
                          {t('do_not_have_account')}
                        </Link>
                      </>
                    );
                  case 'sign_up':
                    return <Link href={LOGIN_PAGE_ROUTE}>{t('already_have_account')}</Link>;
                  case 'forgotten_password':
                    return <Link href={LOGIN_PAGE_ROUTE}>{t('already_have_account')}</Link>;

                  default:
                    return null;
                }
              })()}
            </div>
          </div>

          <div className="fixed bottom-0 w-full p-4 mx-auto text-center">
            <p className="text-xs text-scale-900 sm:mx-auto sm:max-w-sm">
              {t('agree_to_flux_terms')}
              <Link className="underline" href={TOS_PAGE_ROUTE}>
                {t('tos')}
              </Link>
              &nbsp;,&nbsp;
              <Link className="underline" href={PRIVACY_PAGE_ROUTE}>
                {t('pp')}
              </Link>
              {t('receive_updates')}
            </p>
          </div>
        </div>
      </>
    );

  return <></>;
};

export default LoginComponent;
